import * as React from "react";
import { Themes } from "../@types/data";
import { darkBgColours, colours, baseColours } from "../themes/colours";

export interface InterfaceTheme {
  bgColours: {
    [key in keyof typeof darkBgColours]: string;
  };
  colours: {
    [key in keyof typeof colours]: string;
  };
  baseColours: {
    [key in keyof typeof baseColours]: string;
  };
  theme: Themes;
  toggle: (params?: { theme?: Themes; saveSetting?: boolean }) => void;
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const ThemeContext = React.createContext<InterfaceTheme>({});
