import React, { useEffect, useState, useContext } from "react";
import {
  darkBgColours,
  lightBgColours,
  colours as darkColours,
  lightColours,
  baseColours
} from "../themes/colours";

import { ThemeContext, InterfaceTheme } from "../context/index";
import { Themes } from "../@types/data";

const THEME_LOCAL_STORAGE = "papercupTheme";

export const useTheme = () => useContext<InterfaceTheme>(ThemeContext);

export const useAsDefaultTheme = (theme?: Themes) => {
  const themeContext = useContext<InterfaceTheme>(ThemeContext);
  const [previousTheme] = useState(themeContext.theme);
  return useEffect(() => {
    // Find a way to trigger this only on mount
    themeContext.toggle({ theme, saveSetting: false });
    return () => {
      return themeContext.toggle({
        theme: previousTheme,
        saveSetting: false
      });
    };
  }, []);
};

export function ThemeProvider(props: { children: React.ReactNode }) {
  const [theme, setTheme] = useState<Themes>("light");
  const [colours, setColours] = useState(lightColours);
  const [bgColours, setBgColours] = useState(lightBgColours);

  const applyThemeClass = (theme: Themes) => {
    const root = document.getElementsByTagName("html")[0];
    root.className = theme;
  };

  useEffect(() => {
    if (window && window.localStorage) {
      const themeFromLocalStorage = window.localStorage.getItem(
        THEME_LOCAL_STORAGE
      );
      if (themeFromLocalStorage) {
        setTheme(themeFromLocalStorage as Themes);
      }
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      setColours(darkColours);
      setBgColours(darkBgColours);
      applyThemeClass(theme);
    } else {
      setColours(lightColours);
      setBgColours(lightBgColours);
      applyThemeClass(theme);
    }
  }, [theme]);

  const toggle: InterfaceTheme["toggle"] = params => {
    const newTheme: Themes = params?.theme
      ? params.theme
      : theme === "dark"
      ? "light"
      : "dark";
    // Some pages need to be only in one theme
    if (params?.saveSetting !== false && window && window.localStorage) {
      window.localStorage.setItem(THEME_LOCAL_STORAGE, newTheme);
    }
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider
      value={{
        baseColours,
        theme,
        toggle,
        colours,
        bgColours
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
